//资料
const DATAROUTER = [
    {
        path: '/datamodule',
        component: () => import('../views/DataModule/index.js')
    },
    {
        path: '/datamodule-detail',
        component: () => import('../views/DataModule/detailPage.js')
    },
    {
        path: '/datamodule-previewfile',
        component: () => import('../views/DataModule/previewFile.js')
    }
];

export default DATAROUTER;