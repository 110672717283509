//轻骑兵模块
const BUSINESSPLATFORMROUTES = [
    {
        path: '/businessplatform-files',
        component: () => import('../views/BusinessPlatform/businessPlatFormFiles')
    },
    {
        path: '/businessplatform-previewfile',
        component: () => import('../views/BusinessPlatform/businessPreviewPlatFormFile')
    }
];

export default BUSINESSPLATFORMROUTES;