export const ApiUrl = `${process.env.REACT_APP_SERVICE77}/api/SolutionPortal`;
export const ApiloginUrl = `${process.env.REACT_APP_SERVICE77}/Home`;
export const ApiviewUrl = `${process.env.REACT_APP_SERVICE77}/api/pocapply`;
export const Ipcheck = `${process.env.REACT_APP_SERVICE77}/api/pocapply/GetAreaByIp`;
//权限功能
export const AuthorityApi = `${process.env.REACT_APP_SERVICE77}/api/pocapply/GetRoleInfoByLoginName`;
// isv模块
export const IsvConfigApi = `${process.env.REACT_APP_EOSINTERFACE}/permission/v1`;
export const IsvnceSolutionApi = `${process.env.REACT_APP_EOSINTERFACE}/pocapply/v1`;

export const TechnologyVersionApi = 'https://solutionhub-api.h3c.com';
export const TechnologySolutionApi = `${process.env.REACT_APP_EOSINTERFACE}/technology/v1`;
export const IndustrySolutionApi = `${process.env.REACT_APP_EOSINTERFACE}/industry/v1`;
export const loginUrl = `${process.env.REACT_APP_EOSINTERFACE}/login/v1`;