//新版图谱模块(商业解决方案、行业解决方案)
const SOLUTIONROUTES = [
    {
        path: '/businesssolution',
        component: () => import('../components/BusinessSolution/businessSolution.js')
    },
    {
        path: '/businesssolution-detail',
        component: () => import('../components/BusinessSolution/BusinessSolutionDetail.js')
    },
    {
        path: '/businesssolution-previewfile',
        component: () => import('../components/BusinessSolution/previewBusinessSolutionFile.js')
    },
    {
        path: '/industrysolution',
        component: () => import('../components/IndustrySolution/industrySolution.js')
    },
    {
        path: '/industrysolution-detail',
        component: () => import('../components/IndustrySolution/IndustrySolutionDetail.js')
    },
    {
        path: '/industrysolution-previewfile',
        component: () => import('../components/IndustrySolution/previewIndustrySolutionFile.js')
    }
];

export default SOLUTIONROUTES;