// 求助
const SUPPORT = [
    {
        path: '/support/list',
        component: () => import('../views/Support/list.js')
    },
    {
        path: '/support/setup',
        component: () => import('../views/Support/setup.js')
    },
    {
        path: '/support/detail',
        component: () => import('../views/Support/detail.js')
    },
];

export default SUPPORT;