import axios from 'axios';
import { logout } from './index';

const S3Request = axios.create();
S3Request.interceptors.request.use(
    function (config) {
        // 检查是否有 skipAuth 标志，如果没有则添加 token
        if (!config.skipAuth) {
            let Authorization = sessionStorage.getItem('solutionToken');
            if (Authorization) {
                config.headers.Authorization = Authorization;
            }
        }else{
            config.headers.Authorization = '';
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

S3Request.interceptors.response.use(
    function (response) {
        let { code } = response.data;
        if (code == 20206) logout();
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default S3Request;