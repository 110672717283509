//技术解决方案模块
const TECHNOLOGYSOLUTION = [
    {
        path: '/technologysolution-detail',
        component: () => import('../views/TechnologySolution/detail.js')
    },
    {
        path: '/technologysolution-previewfile',
        component: () => import('../views/TechnologySolution/previewFile.js')
    }
];

export default TECHNOLOGYSOLUTION;