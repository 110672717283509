import axios from "axios";
import { loginUrl } from '@api/interfaceAddress.js';
//获取登录域账号
export function getUserCode() {
    let userCode = sessionStorage.getItem('userCode');
    let res = '';
    if (userCode) res = userCode;
    return res;
}

//格式化cookie
export function getCookie() {
    let cookieObj = {};
    if (document.cookie) {
        let arr = document.cookie.split(';');
        for (let item of arr) {
            let data = item.split('=');
            let key = data[0].trim();
            cookieObj[key] = data[1];
        }
    }
    return cookieObj;
}

//从地址栏取值，querystring官方不推荐使用了
export function getUrlParams(searchUrl) {
    let searchParams = new URLSearchParams(searchUrl);
    let paramsObj = {};
    for (let [key, value] of searchParams) {
        paramsObj[key] = value;
    }
    return paramsObj;
}

//退出登录
export function logout() {
    sessionStorage.clear();
    let entirePath = window.location.href;
    entirePath = encodeURIComponent(entirePath);
    axios.get(`${loginUrl}/logout?redirectUrl=${entirePath}`).then(res =>{
        document.location.replace(res.data)
    }).catch(error =>{
        console.error(error);
    })
}

//根据账号获取eostoken
export function getSolutionTokenByEmpnumber() {
    let data = {};
    if (process.env.REACT_APP_ENV === 'uat_production') {
        data = {
            account: "eos_atlas_api",
            password: 'eos_atlas_api'
        };  //测试环境获取eostoken
    } else {
        data = {
            account: "eos_app_isales",
            password: 'aKwUPpTL2JZMP8OK'
        };  //正式环境获取eostoken
    }
    return axios({
        url: process.env.REACT_APP_GETEOSTOKEN,
        method: 'post',
        data
    });
}

//产生一个随机guid
export function guid() {
    return 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);     
        return v.toString(16);
    });
}