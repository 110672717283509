import React from 'react';

import ECOLOGYROUTES from './ecologyRoutes';  //生态图谱模块
import SOLUTIONROUTES from './solutionRoutes';  //新版图谱模块(商业解决方案、行业解决方案)
import TECHNOLOGYSOLUTION from './technologySolution';  //技术解决方案模块
import PLATFORMROUTES from './platformRoutes';  //重装旅解决方案模块
import RESOURCEPOOLROUTES from './resourcepoolRoutes';  //应用资源池模块
import BUSINESSPLATFORMROUTES from './businessPlatformRoutes';  //轻骑兵模块
import DATAROUTER from './dataRouter';  //资料
import NAMELIST from './nameList';  //名单
import SOLUTIONGUIDN from './solutionGuidn';  //解决方案图谱
import SUPPORT from './support';  // 求助
import SOLUTIONREGISTERSYS from './solutionRegisterSys';  // 解决方案注册系统
import ECOLOGYATLASROUTES from './ecologyAtlasRoutes';  // 生态图谱
import EXPERTROUTES from './expertRoutes';  // 专家墙

//旧版技术解决方案模块
const OLDTECHNOLOGYSOLUTION = [
    {
        path: '/technologysolution-old',
        component: () => import('../components/TechnologySolution/index.js')
    },
    {
        path: '/technologysolution-detail-old',
        component: () => import('../components/TechnologySolution/detail.js')
    },
    {
        path: '/technologysolution-previewfile-old',
        component: () => import('../components/TechnologySolution/previewFile.js')
    }
];

//求解链接周活动
const ACTIVITY = [
    // {
    //     path: '/connectionweek',
    //     component: () => import('../views/ConnectionWeek-21year/index')  //21年
    // },
    // {
    //     path: '/activity',
    //     component: () => import('../views/ConnectionWeek-20year/Activity')  //20年
    // }
];

const routes = [
    {
        path: '/index',
        component: () => import('../components/ISV/List')  //旧首页
    },
    {
        path: '/',
        component: () => import('../views/NewHome/index.js')  //新首页
    },
    {
        path: '/solve',
        component: () => import('../components/Solve/Solve')  //求解数据看板，管理员角色才能看到
    },
    ...OLDTECHNOLOGYSOLUTION,
    ...ECOLOGYROUTES,
    ...SOLUTIONROUTES,
    ...TECHNOLOGYSOLUTION,
    ...PLATFORMROUTES,
    ...RESOURCEPOOLROUTES,
    ...BUSINESSPLATFORMROUTES,
    ...DATAROUTER,
    ...NAMELIST,
    ...SOLUTIONGUIDN,
    ...SUPPORT,
    ...SOLUTIONREGISTERSYS,
    ...ECOLOGYATLASROUTES,
    ...EXPERTROUTES,
];

function createRouter() {
    let router = [];
    for (let route of routes) {
        let newRoute = {
            path: route.path,
            component: React.lazy(route.component)
        };
        router.push(newRoute);
    }
    return router;
}

const router = createRouter();

export default router;