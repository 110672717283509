//应用资源池模块
const RESOURCEPOOLROUTES = [
    {
        path: '/resourcepool-detail',
        component: () => import('../components/ResourcePool/index.js')
    },
    {
        path: '/resourcepool-previewfile',
        component: () => import('../components/ResourcePool/previewResourcePoolFile')
    }
];

export default RESOURCEPOOLROUTES;