//重装旅解决方案模块
const PLATFORMROUTES = [
    {
        path: '/platform-files',
        component: () => import('../views/HeavyBrigadeSolution/platFormFiles')
    },
    {
        path: '/platform-previewfile',
        component: () => import('../views/HeavyBrigadeSolution/previewPlatFormFile')
    }
];

export default PLATFORMROUTES;