//生态图谱模块
const ECOLOGYROUTES = [
    {
        path: '/oldecology',
        component: () => import('../components/Ecology/Ecology')
    },
    {
        path: '/isv-configuration',
        component: () => import('../components/ISV/isvConfiguration')
    },
    {
        path: '/isvdetail',
        component: () => import('../components/EcologicalMap/ecologySolution')
    },
    {
        path: '/ecology-detail',
        component: () => import('../components/Ecology/EcologyDetail')
    },
    {
        path: '/ecology',
        component: () => import('../components/EcologicalMap/ecology')
    },
    {
        path: '/ecology-solution',
        component: () => import('../components/EcologicalMap/ecologySolution')
    },
    {
        path: '/ecology-partner',
        component: () => import('../components/EcologicalMap/ecologyPartner')
    },
    {
        path: '/ecology-previewfile',
        component: () => import('../components/EcologicalMap/previewFile')
    },
    {
        path: '/ecology-leader',
        component: () => import('../components/EcologicalMap/ecologyLeader')
    },
];

export default ECOLOGYROUTES;