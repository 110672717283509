//解决方案图谱
const SOLUTIONGUIDN = [
    {
        path: '/guidnpage',
        component: () => import('../views/SolutionGuidn/guidn.js')
    },
    {
        path: '/newguidnpage',
        component: () => import('../views/SolutionGuidn/newGuidn.js')
    },
    {
        path: '/guidndetail',
        component: () => import('../views/SolutionGuidn/guidnDetail.js')
    },
    {
        path: '/newGuidndetail',
        component: () => import('../views/SolutionGuidn/newGuidnDetail.js')
    },
    {
        path: '/guidnGcdetail',
        component: () => import('../views/SolutionGuidn/guidnGcDetail.js')
    },
    {
        path: '/solutionguidn-file',
        component: () => import('../views/SolutionGuidn/guidnfile.js')
    },
    {
        path: '/solutionguidn-previewfile',
        component: () => import('../views/SolutionGuidn/previewFile.js')
    },
    {
        path: '/solutionguidn-basic-file',
        component: () => import('../views/SolutionGuidn/guidnbasicfile.js')
    },
    {
        path: '/solutionsearch',
        component: () => import('../views/SolutionGuidn/solutionsearch.js')
    },
    {
        path: '/newsolutionsearch',
        component: () => import('../views/SolutionGuidn/newSolutionsearch.js')
    },
];

export default SOLUTIONGUIDN;