import axios from 'axios';
import S3Request from "../utils/S3Request";

const SERVICE77 = process.env.REACT_APP_SERVICE77;
const JudgeUserDepart = `${SERVICE77}/api/SolutionPortal/JudgeUserDepart`;
const LogApi = `${SERVICE77}/api/SolutionPortal/SolutionLogMessage`;
const GetHeadPortrait = `${SERVICE77}/api/SolutionPortal/GetHeadPortrait`;
const GetUserRoles = `${SERVICE77}/api/SolutionPortal/GetUserRoles`;
const GetRoleInfoByLoginName = `${SERVICE77}/api/pocapply/GetRoleInfoByLoginName`;  //重装旅获取权限

const uploadFeedBack = `${process.env.REACT_APP_EOSINTERFACE}/port/v1/uploadFeedBack`;  //统一的意见反馈接口
const updateFocusOn = `${process.env.REACT_APP_EOSINTERFACE}/port/v1/updateFocusOn`;  //端到端关注功能
const getPersonInfo = `${process.env.REACT_APP_EOSINTERFACE}/permission/v1/getPersonInfo`;  //根据域账号获取人员信息


export function getPersonInfoRequest(params) {
    return S3Request({
        method: 'get',
        url: getPersonInfo,
        params
    });
}

export function updateFocusOnRequest(data) {
    return S3Request({
        method: 'post',
        url: updateFocusOn,
        data
    });
}

export function uploadFeedBackRequest(data) {
    return S3Request({
        method: 'post',
        url: uploadFeedBack,
        data
    });
}

export function judgeUserDepartRequest(data) {
    return axios({
        url: JudgeUserDepart,
        method: 'post',
        data
    });
}

export function logApiRequest(data) {
    data = new URLSearchParams(data);
    return axios({
        url: LogApi,
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data
    });
}

export function getHeadPortraitRequest(data) {
    return axios({
        url: GetHeadPortrait,
        method: 'post',
        data
    });
}

export function getUserRolesRequest(data) {
    data = new URLSearchParams(data);
    return axios({
        url: GetUserRoles,
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data
    });
}

export function getRoleInfoByLoginNameRequest(params) {
    return axios({
        url: GetRoleInfoByLoginName,
        method: 'get',
        params
    });
}