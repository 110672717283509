import "core-js/es";
import 'core-js/es/set';
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {
    HashRouter as Router,
    Route,
    Switch
} from 'react-router-dom';
import { createHashHistory } from 'history';
import { message, Skeleton } from "antd";

import { ApiloginUrl, loginUrl } from '@api/interfaceAddress.js';
import router from './router/index.js';
import { getSolutionTokenByEmpnumber, getUrlParams } from './utils/index.js';
import {
    judgeUserDepartRequest,
    logApiRequest,
    getUserRolesRequest
} from './api/app.js';
import axios from 'axios';
import './index.less';

const history = createHashHistory();
let basename = '/';
if (document.location.host == '10.18.194.91') basename = '/solutionweb';

class QiuJie extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }
    // 组件渲染后调用
    componentDidMount() {
        this.setRemStyle();
        // if (process.env.NODE_ENV === 'development') {
        //     sessionStorage.setItem("usertoken", 'qys3433');
        //     sessionStorage.setItem("userCode", 'qys3433');
        //     sessionStorage.setItem("isvUserRoles", true);
        //     sessionStorage.setItem("isisvLeader", true);
        // }
        let { hash } = document.location;
        if (hash.split('?')[1]) {
            let urlParams = getUrlParams(hash.split('?')[1]);
            if (urlParams) {
                let { userToken, loginCode } = urlParams;
                if (userToken) {
                    sessionStorage.setItem("usertoken", userToken);
                    sessionStorage.setItem("userCode", loginCode);
                    sessionStorage.setItem("isWxWork", 1);
                    this.judgeUserDepart(userToken);
                } else {
                    const usertoken = sessionStorage.getItem("usertoken");
                    this.login(usertoken);
                }
            } else {
                const usertoken = sessionStorage.getItem("usertoken");
                this.login(usertoken);
            }
        } else {
            const usertoken = sessionStorage.getItem("usertoken");
            this.login(usertoken);
        }
        window.scrollTo(0, 0);
    }

    setRemStyle() {
        let designSize = 1920;  //参考尺寸
        let htmlWidth = document.documentElement.clientWidth;
        let rem = htmlWidth * 100 / designSize;
        document.documentElement.style.fontSize = `${rem}px`;
    }

    login(usertoken) {
        if (!usertoken) {
            this.judgeLogin();
        } else {
            this.judgeUserDepart(usertoken);
        }
    }

    judgeLogin(value) {
        const ticket = this.getQueryString();
        if (!ticket || value == '1') {
            let path = document.location.href;
            path = encodeURIComponent(path);
            // if (process.env.NODE_ENV === 'development') document.location.href = `${ApiloginUrl}/SSOLogin`;
            // else document.location.href = `${ApiloginUrl}/SSOLogin?backUrl=${path}`;
            axios.get(`${loginUrl}/ssoLogin?redirectUrl=${path}`).then(res =>{
                document.location.href = res.data
            }).catch(error =>{
                console.error(error);
            })
        } else {
            this.getToken(ticket)
        }
    }
    getToken(ticket){
        axios.get(`${loginUrl}/getTokenByH3CTicket?h3cTicket=${ticket}&currentUrl=${window.location.origin}`).then(res =>{
            let {token,domainAccount} = res.data
            if(token){
                sessionStorage.setItem('solutionToken',token);
                sessionStorage.setItem("userCode",domainAccount);
                this.solutionportal(domainAccount);  //记录登录日志
                this.getUserRoles("解决方案后台非一线员工", "isvUserRoles", domainAccount);
                this.getUserRoles("解决方案ISV后台主管", "isisvLeader", domainAccount);
                this.setHref()
            }else{
                this.judgeLogin('1');
            }
        }).catch(error =>{
            console.error(error)
        })
    }
    getQueryString() {
        let searchUrl = document.location.href.split('?')[1]
        let searchParams = new URLSearchParams(searchUrl)
        let ticket = searchParams.get('h3c_ticket')
        if (ticket) return ticket;
        else return null;
    }

    judgeUserDepart(token) {
        let username = sessionStorage.getItem("userCode");
        const ticket = this.getQueryString();
        if (!username) {
            let data = { token };
            judgeUserDepartRequest(data).then(res => {
                let { code, data } = res.data;
                if (code == 200) {
                    let { loginName } = data;
                    sessionStorage.setItem("userCode", loginName);
                    this.solutionportal(loginName);  //记录登录日志
                    this.getUserRoles("解决方案后台非一线员工", "isvUserRoles", loginName);
                    this.getUserRoles("解决方案ISV后台主管", "isisvLeader", loginName);
                } else {
                    message.error('获取用户基本信息失败');
                }
                this.getToken(ticket);
            }).catch(error => {
                console.log(error);
            });
        } else {
            this.getToken(ticket);
        }
    }

    getUserRoles(RoleCode, sessionName, loginName) {
        let params = {
            RoleCode: RoleCode,
            token: loginName
        };
        getUserRolesRequest(params).then(res => {
            let { code, data } = res.data;
            if (code == 200) sessionStorage.setItem(sessionName, data);
            else sessionStorage.setItem(sessionName, false);
        }).catch(error => {
            console.log(error);
        });
    }

    //记录登录日志
    solutionportal(loginName) {
        let data = {
            token: loginName,
            EntityTypeCode: "SolutionAPI",
            EntityTypeChildCode: "求解web登录",
            Operator: loginName,
            IsNo: "1"
        };
        logApiRequest(data);
    }

    // getSolutionToken() {
    //     const solutionToken = sessionStorage.getItem('solutionToken');
    //     if (!solutionToken) {
    //         getSolutionTokenByEmpnumber().then(res => {
    //             let { code, data } = res.data;
    //             if (code == 20220) {
    //                 let { token } = data;
    //                 sessionStorage.setItem('solutionToken', `Bearer ${token}`);
    //                 this.setHref();
    //             } else {
    //                 message.error('获取eos权限失败');
    //                 this.setState({ show: false });
    //             }
    //         }).catch(error => {
    //             console.log(error);
    //         });
    //     } else {
    //         this.setHref();
    //     }
    // }

    setHref() {
        /**
         * 如果是丛某一个具体的路由进入网站，判断用户登录成功后，需要跳转到这个具体的路由页面
         * 否则就跳转到首页
         */
        const { href } = document.location;
        let hrefData = href.split('?');
        let backUrl = hrefData[0];
        let search = hrefData.slice(1);
        let queryData = {};
        if (search.length) {
            for (let item of search) {
                if (item.search('&') != -1) {
                    let arr = item.split('&');
                    for (let ele of arr) {
                        let data = ele.split('=');
                        queryData[data[0]] = data[1];
                    }
                } else {
                    let data = item.split('=');
                    queryData[data[0]] = data[1];
                }
            }
        }
        if (search.length) {
            let query = []; 
            for (let key in queryData) {
                //登录成功后服务端会在地址栏上增加token参数，要排除掉
                if (key !== 'token') query.push(`${key}=${queryData[key]}`);
            }
            if (query.length) document.location.href = `${backUrl}?${query.join('&')}`;
            else document.location.href = backUrl;
        } else {
            document.location.href = backUrl;
        }
        this.setState({ show: true });
    }

    render() {
        const { show } = this.state;
        if (show) {
            return (
                <Suspense fallback={<div></div>}>
                    <Router basename={basename} history={history}>
                        <Switch>
                            {
                                router.map(route => {
                                    return (
                                        <Route key={route.path} exact {...route} />
                                    )
                                })
                            }
                        </Switch>
                    </Router>
                </Suspense>
            );
        } else {
            return (
                <div style={{paddingTop: '30px'}}>
                    <Skeleton active />
                </div>
            );
        }
    }
}

ReactDOM.render(<QiuJie />, document.getElementById('root'));
serviceWorker.unregister();
