//专家墙模块
const EXPERTROUTES = [
    {
        path: '/expertWall',
        component: () => import('../views/NewHome/components/expertHelp/expertWall.js')
    },
    {
        path: '/expertHelpProcess',
        component: () => import('../views/NewHome/components/expertHelp/expertHelpProcess.js')
    },
    {
        path: '/personalCenter',
        component: () => import('../views/NewHome/components/expertHelp/personalCenter.js')
    },
    {
        path: '/GoldBeanDetail',
        component: () => import('../views/NewHome/components/expertHelp/GoldBeanDetail.js')
    },
    {
        path: '/helpList',
        component: () => import('../views/NewHome/components/expertHelp/helpList.js')
    },

];

export default EXPERTROUTES;