//生态图谱模块
const ECOLOGYATLASROUTES = [
    {
        path: '/ecology-atlas',
        component: () => import('../views/EcologyAtlas/index')
    },
    {
        path: '/ecology-atlas/partners',
        component: () => import('../views/EcologyAtlas/partners')
    },
    {
        path: '/ecology-atlas/partners/solution',
        component: () => import('../views/EcologyAtlas/solutionDetail')
    },
];

export default ECOLOGYATLASROUTES;