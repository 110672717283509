// 解决方案注册系统
const SOLUTIONREGISTERSYS = [
    {
        path: '/solutionRegisterSys/list',
        component: () => import('../views/SolutionRegisterSys/index.js')
    },
    {
        path: '/solutionRegisterSys/apply',
        component: () => import('../views/SolutionRegisterSys/index.js')
    },
    {
        path: '/solutionRegisterSys/detail',
        component: () => import('../views/SolutionRegisterSys/index.js')
    },
];

export default SOLUTIONREGISTERSYS;